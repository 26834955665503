import React from "react";

function SuperiorPatient() {
    return (
        <svg
            width="82"
            height="82"
            viewBox="0 0 82 82"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <ellipse cx="30" cy="34.5" rx="28" ry="27.5" fill="#F3F3FF" />
            <g style={{ mixBlendMode: "multiply" }}>
                <circle
                    cx="46.5873"
                    cy="6.58711"
                    r="3"
                    transform="rotate(12.7246 46.5873 6.58711)"
                    fill="#E8E8FF"
                />
            </g>
            <path
                d="M14.3398 48.6414C14.0676 48.6414 13.8066 48.5334 13.6141 48.341C13.4216 48.1487 13.3134 47.8878 13.3134 47.6158V46.3469H12.0264C11.7542 46.3469 11.4931 46.2388 11.3006 46.0465C11.1081 45.8541 11 45.5933 11 45.3212C11 45.0492 11.1081 44.7883 11.3006 44.596C11.4931 44.4036 11.7542 44.2956 12.0264 44.2956H13.3134V43.0257C13.3134 42.7536 13.4216 42.4928 13.6141 42.3004C13.8066 42.1081 14.0676 42 14.3398 42C14.6121 42 14.8731 42.1081 15.0656 42.3004C15.2581 42.4928 15.3662 42.7536 15.3662 43.0257V44.2942H16.6547C16.9269 44.2942 17.188 44.4023 17.3804 44.5946C17.5729 44.787 17.6811 45.0478 17.6811 45.3199C17.6811 45.5919 17.5729 45.8528 17.3804 46.0451C17.188 46.2375 16.9269 46.3455 16.6547 46.3455H15.3676V47.6144C15.3678 47.7493 15.3413 47.883 15.2898 48.0077C15.2382 48.1323 15.1625 48.2456 15.067 48.341C14.9715 48.4364 14.8581 48.5121 14.7334 48.5636C14.6086 48.6152 14.4749 48.6416 14.3398 48.6414Z"
                fill="#4D469D"
            />
            <path
                d="M11.3672 50.1824C11.3672 49.9485 11.2977 49.7198 11.1675 49.5253C11.0374 49.3309 10.8525 49.1793 10.6362 49.0899C10.4199 49.0004 10.1818 48.9771 9.95225 49.0228C9.72265 49.0686 9.51178 49.1813 9.34632 49.3468C9.18085 49.5123 9.0682 49.7231 9.02263 49.9526C8.97707 50.182 9.00063 50.4198 9.09033 50.6359C9.18003 50.852 9.33185 51.0367 9.52658 51.1665C9.72131 51.2964 9.9502 51.3656 10.1843 51.3655C10.4982 51.3653 10.7991 51.2406 11.0209 51.0187C11.2427 50.7968 11.3673 50.496 11.3672 50.1824Z"
                fill="#4D469D"
            />
            <path
                d="M71.8069 64.2097C71.8335 63.8675 71.7579 63.5251 71.5898 63.2258C71.4216 62.9265 71.1684 62.6837 70.8622 62.5281C70.556 62.3726 70.2105 62.3113 69.8695 62.352C69.5285 62.3927 69.2072 62.5335 68.9464 62.7567C68.6856 62.9799 68.4969 63.2754 68.4041 63.6059C68.3114 63.9363 68.3188 64.2869 68.4255 64.6132C68.5322 64.9395 68.7332 65.2268 69.0033 65.4389C69.2734 65.651 69.6003 65.7784 69.9427 65.8048C70.17 65.8224 70.3985 65.7951 70.6152 65.7245C70.8318 65.6539 71.0324 65.5413 71.2055 65.3932C71.3786 65.2451 71.5209 65.0643 71.624 64.8612C71.7272 64.6582 71.7894 64.4368 71.8069 64.2097Z"
                fill="#4D469D"
            />
            <path
                d="M67.7449 68.0114C67.7568 67.8547 67.7219 67.6979 67.6447 67.5609C67.5675 67.4239 67.4514 67.3128 67.3111 67.2417C67.1708 67.1706 67.0125 67.1427 66.8564 67.1614C66.7002 67.1801 66.5531 67.2447 66.4336 67.3469C66.3142 67.4492 66.2277 67.5846 66.1852 67.7359C66.1428 67.8873 66.1461 68.0478 66.1949 68.1973C66.2437 68.3468 66.3358 68.4784 66.4594 68.5757C66.583 68.6729 66.7327 68.7314 66.8896 68.7436C67.0999 68.7599 67.308 68.692 67.4682 68.5549C67.6284 68.4178 67.7276 68.2228 67.7439 68.0126L67.7449 68.0114Z"
                fill="#4D469D"
            />
            <path
                d="M78.311 11.9629L77.9622 11.0397L77.0389 10.691C74.0677 9.5689 70.9447 9 67.7566 9C60.7503 9 54.1632 11.7284 49.2089 16.6828L42.1847 23.707L26.4857 24.4684L13.3462 37.6078L24.6751 41.2165L20.4859 45.4057L22.9331 47.8528L17.5114 56.1629L32.8387 71.4905L41.149 66.0689L43.5962 68.5161L47.7855 64.3268L51.3939 75.6558L64.5337 62.5163L65.295 46.8174L72.3192 39.7932C79.5956 32.5167 81.9475 21.5926 78.311 11.9629ZM21.5106 35.6227L28.3826 28.7509L37.5872 28.3045L28.1531 37.7386L21.5106 35.6227ZM33.4212 65.8936L23.1084 55.5808L26.0903 51.0102L37.9916 62.9117L33.4212 65.8936ZM60.251 60.6195L53.3792 67.4913L51.2634 60.8489L60.6975 51.4148L60.251 60.6195ZM43.5962 62.3367L26.6651 45.4057L52.2985 19.7723C56.4276 15.6433 61.9173 13.3694 67.7566 13.3694C70.0807 13.3694 72.3627 13.7317 74.5541 14.4474C77.0958 22.2367 75.0585 30.8743 69.2295 36.7035L43.5962 62.3367Z"
                fill="#8989C2"
            />
            <path
                d="M63.1546 19.293C61.4039 19.293 59.758 19.9747 58.5202 21.2126C55.9646 23.768 55.9646 27.9261 58.5202 30.4817C59.758 31.7195 61.404 32.4013 63.1546 32.4013C64.9051 32.4013 66.5511 31.7195 67.7889 30.4817C70.3445 27.9263 70.3445 23.7682 67.7889 21.2126C66.5512 19.9747 64.9052 19.293 63.1546 19.293ZM64.6995 27.392C64.2868 27.8047 63.7382 28.032 63.1547 28.032C62.5712 28.032 62.0226 27.8047 61.61 27.3922C60.7581 26.5403 60.7581 25.1543 61.61 24.3024C62.0226 23.8898 62.5712 23.6626 63.1547 23.6626C63.7382 23.6626 64.2868 23.8898 64.6995 24.3024C65.5512 25.1541 65.5512 26.5403 64.6995 27.392Z"
                fill="#FF9900"
            />
            <path
                d="M10.002 63.7072L14.9538 58.7552L18.0435 61.8448L13.0916 66.7967L10.002 63.7072ZM25.2937 79L22.2041 75.9104L27.156 70.9585L30.2456 74.0481L25.2937 79ZM13.6458 72.0479L20.946 64.7477L24.0356 67.8374L16.7354 75.1376L13.6458 72.0479Z"
                fill="#FF9900"
            />
        </svg>
    );
}

export default SuperiorPatient;
