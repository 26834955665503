import React from "react";

function MachineLearning() {
    return (
        <svg
            width="82"
            height="82"
            viewBox="0 0 82 82"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <ellipse
                cx="30.6854"
                cy="30.191"
                rx="27.6854"
                ry="27.191"
                fill="#F3F3FF"
            />
            <g style={{ mixBlendMode: "multiply" }}>
                <circle
                    cx="55.9847"
                    cy="53.0187"
                    r="2.96629"
                    transform="rotate(12.7246 55.9847 53.0187)"
                    fill="#E8E8FF"
                />
            </g>
            <path
                d="M17.19 7.56682C16.9208 7.56682 16.6627 7.45997 16.4724 7.26979C16.2821 7.0796 16.1751 6.82165 16.1751 6.55269V5.29805H14.9025C14.6334 5.29805 14.3753 5.19121 14.1849 5.00103C13.9946 4.81084 13.8877 4.55288 13.8877 4.28392C13.8877 4.01495 13.9946 3.75701 14.1849 3.56682C14.3753 3.37663 14.6334 3.26979 14.9025 3.26979H16.1751V2.01413C16.1751 1.74517 16.2821 1.48722 16.4724 1.29703C16.6627 1.10685 16.9208 1 17.19 1C17.4592 1 17.7173 1.10685 17.9076 1.29703C18.098 1.48722 18.2049 1.74517 18.2049 2.01413V3.26843H19.4788C19.748 3.26843 20.0061 3.37528 20.1965 3.56546C20.3868 3.75565 20.4937 4.0136 20.4937 4.28256C20.4937 4.55153 20.3868 4.80947 20.1965 4.99966C20.0061 5.18985 19.748 5.2967 19.4788 5.2967H18.2062V6.55133C18.2064 6.68473 18.1803 6.81687 18.1292 6.94015C18.0782 7.06344 18.0034 7.17545 17.909 7.26979C17.8146 7.36412 17.7025 7.43891 17.5791 7.48988C17.4557 7.54085 17.3235 7.567 17.19 7.56682Z"
                fill="#4D469D"
            />
            <path
                d="M14.2508 9.09044C14.2507 8.85913 14.182 8.63304 14.0533 8.44075C13.9247 8.24847 13.7418 8.09862 13.5279 8.01018C13.3141 7.92174 13.0787 7.89867 12.8517 7.94389C12.6247 7.98911 12.4162 8.10058 12.2526 8.26421C12.089 8.42785 11.9776 8.63629 11.9325 8.86318C11.8875 9.09007 11.9108 9.3252 11.9995 9.53886C12.0882 9.75252 12.2383 9.9351 12.4308 10.0635C12.6234 10.1919 12.8497 10.2604 13.0812 10.2602C13.3915 10.2601 13.689 10.1367 13.9084 9.91735C14.1277 9.69799 14.2509 9.40054 14.2508 9.09044Z"
                fill="#4D469D"
            />
            <path
                d="M77.9666 77.3421C77.9929 77.0038 77.9181 76.6652 77.7519 76.3693C77.5856 76.0733 77.3352 75.8333 77.0325 75.6795C76.7297 75.5257 76.3881 75.4651 76.0509 75.5053C75.7138 75.5456 75.3961 75.6848 75.1382 75.9055C74.8803 76.1262 74.6937 76.4184 74.602 76.7451C74.5103 77.0719 74.5177 77.4185 74.6232 77.7411C74.7286 78.0637 74.9274 78.3479 75.1945 78.5576C75.4615 78.7673 75.7848 78.8932 76.1234 78.9193C76.3481 78.9368 76.574 78.9098 76.7882 78.84C77.0024 78.7701 77.2008 78.6588 77.372 78.5124C77.5431 78.3659 77.6837 78.1872 77.7858 77.9864C77.8878 77.7856 77.9492 77.5667 77.9666 77.3421Z"
                fill="#4D469D"
            />
            <path
                d="M73.9503 81.1012C73.962 80.9462 73.9276 80.7912 73.8513 80.6557C73.7749 80.5203 73.6601 80.4104 73.5214 80.3401C73.3826 80.2698 73.2262 80.2422 73.0717 80.2607C72.9173 80.2792 72.7718 80.3431 72.6537 80.4442C72.5356 80.5453 72.4502 80.6791 72.4082 80.8288C72.3662 80.9784 72.3695 81.1372 72.4178 81.285C72.466 81.4328 72.557 81.5629 72.6792 81.6591C72.8015 81.7552 72.9495 81.8131 73.1046 81.8252C73.3125 81.8412 73.5183 81.7741 73.6767 81.6386C73.8351 81.503 73.9332 81.3102 73.9493 81.1024L73.9503 81.1012Z"
                fill="#4D469D"
            />
            <g clipPath="url(#clip0_45_154)">
                <path
                    d="M18.1502 9.89886V41.8736H4V70.6526C4 75.3178 7.79522 79.1124 12.4597 79.1124H64.7543C69.4188 79.1124 73.2133 75.2515 73.2133 70.5053V9.89886H18.1502ZM18.1502 70.6526C18.1502 73.7902 15.5973 76.3439 12.4597 76.3439C9.32146 76.3439 6.76849 73.7903 6.76849 70.6526V44.6421H18.1502V70.6526ZM70.4448 70.5053C70.4448 73.724 67.8919 76.3439 64.7536 76.3439H18.7002C20.0732 74.8394 20.9187 72.8456 20.9187 70.6526V43.2579V41.8736V12.6674H70.4448V70.5053Z"
                    fill="#8989C2"
                />
                <path
                    d="M26.2712 18.5546V49.1179H46.7278V18.5546H26.2712ZM43.9593 46.3495H29.0399V21.3232H43.9593V46.3495Z"
                    fill="#FF9900"
                />
                <path
                    d="M66.0543 22.6757H50.8273V25.4442H66.0543V22.6757Z"
                    fill="#FF9900"
                />
                <path
                    d="M66.0543 31.1958H50.8273V33.9643H66.0543V31.1958Z"
                    fill="#FF9900"
                />
                <path
                    d="M66.0543 39.7146H50.8273V42.4831H66.0543V39.7146Z"
                    fill="#FF9900"
                />
                <path
                    d="M66.0513 57.4998H25.5337V60.2683H66.0513V57.4998Z"
                    fill="#8989C2"
                />
                <path
                    d="M66.0513 66.9169H25.5337V69.6854H66.0513V66.9169Z"
                    fill="#8989C2"
                />
            </g>
            <defs>
                <clipPath id="clip0_45_154">
                    <rect
                        width="69.2135"
                        height="69.2135"
                        fill="white"
                        transform="translate(4 9.89886)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

export default MachineLearning;
