import React from "react";

function StreamlinedCommunication() {
    return (
        <svg
            width="82"
            height="82"
            viewBox="0 0 82 82"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_45_57)">
                <ellipse cx="49" cy="52.5" rx="28" ry="27.5" fill="#F3F3FF" />
                <g style={{ mixBlendMode: "multiply" }}>
                    <circle
                        cx="22.5871"
                        cy="68.5871"
                        r="3"
                        transform="rotate(12.7246 22.5871 68.5871)"
                        fill="#E8E8FF"
                    />
                </g>
                <path
                    d="M84.367 74.1824C84.3669 73.9485 84.2974 73.7198 84.1673 73.5253C84.0372 73.3309 83.8523 73.1793 83.6359 73.0899C83.4196 73.0004 83.1816 72.9771 82.952 73.0228C82.7224 73.0686 82.5115 73.1813 82.3461 73.3468C82.1806 73.5123 82.068 73.7231 82.0224 73.9526C81.9768 74.182 82.0004 74.4198 82.0901 74.6359C82.1798 74.852 82.3316 75.0367 82.5263 75.1665C82.7211 75.2964 82.95 75.3656 83.1841 75.3655C83.4979 75.3653 83.7988 75.2406 84.0207 75.0187C84.2425 74.7968 84.3671 74.496 84.367 74.1824Z"
                    fill="#4D469D"
                />
                <path
                    d="M72.8069 9.20968C72.8335 8.8675 72.7579 8.52509 72.5898 8.22577C72.4216 7.92645 72.1684 7.68367 71.8622 7.52814C71.556 7.37261 71.2105 7.31132 70.8695 7.35201C70.5285 7.39271 70.2072 7.53354 69.9464 7.75674C69.6856 7.97993 69.4969 8.27545 69.4041 8.6059C69.3114 8.93634 69.3188 9.28688 69.4255 9.61317C69.5322 9.93945 69.7332 10.2268 70.0033 10.4389C70.2734 10.651 70.6003 10.7784 70.9427 10.8048C71.17 10.8224 71.3985 10.7951 71.6152 10.7245C71.8318 10.6539 72.0324 10.5413 72.2055 10.3932C72.3786 10.2451 72.5209 10.0643 72.624 9.86125C72.7272 9.65818 72.7894 9.43678 72.8069 9.20968Z"
                    fill="#4D469D"
                />
                <path
                    d="M68.7449 13.0114C68.7568 12.8547 68.7219 12.6979 68.6447 12.5609C68.5675 12.4239 68.4514 12.3128 68.3111 12.2417C68.1708 12.1706 68.0125 12.1427 67.8564 12.1614C67.7002 12.1801 67.5531 12.2447 67.4336 12.3469C67.3142 12.4492 67.2277 12.5846 67.1852 12.7359C67.1428 12.8873 67.1461 13.0478 67.1949 13.1973C67.2437 13.3468 67.3358 13.4784 67.4594 13.5757C67.583 13.6729 67.7327 13.7314 67.8896 13.7436C68.0999 13.7599 68.308 13.692 68.4682 13.5549C68.6284 13.4178 68.7276 13.2228 68.7439 13.0126L68.7449 13.0114Z"
                    fill="#4D469D"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M61.8106 36.1622C61.8106 35.1173 62.6576 34.2703 63.7025 34.2703H78.8376C79.8825 34.2703 80.7295 35.1173 80.7295 36.1622V66.4325C80.7295 67.4773 79.8825 68.3243 78.8376 68.3243H70.1618L65.0403 73.4459C64.6855 73.8007 64.2043 74 63.7025 74C63.2007 74 62.7195 73.8007 62.3647 73.4459L57.2432 68.3243H37.216C36.1711 68.3243 35.3241 67.4773 35.3241 66.4325V51.2973C35.3241 50.2524 36.1711 49.4054 37.216 49.4054H61.8106V36.1622ZM65.5944 38.054V51.2973C65.5944 52.3422 64.7474 53.1892 63.7025 53.1892H39.1079V64.5406H58.0268C58.5286 64.5406 59.0098 64.7399 59.3646 65.0947L63.7025 69.4326L68.0404 65.0947C68.3952 64.7399 68.8764 64.5406 69.3782 64.5406H76.9457V38.054H65.5944Z"
                    fill="#8989C2"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.27002 5.89189C1.27002 4.84703 2.11705 4 3.16191 4H63.7025C64.7474 4 65.5944 4.84703 65.5944 5.89189V51.2973C65.5944 52.3422 64.7474 53.1892 63.7025 53.1892H28.5402L21.5267 60.2027C20.7879 60.9415 19.59 60.9415 18.8512 60.2027L11.8377 53.1892H3.16191C2.11705 53.1892 1.27002 52.3422 1.27002 51.2973V5.89189ZM5.0538 7.78378V49.4054H12.6214C13.1231 49.4054 13.6043 49.6048 13.9591 49.9596L20.189 56.1894L26.4188 49.9596C26.7736 49.6048 27.2548 49.4054 27.7565 49.4054H61.8106V7.78378H5.0538Z"
                    fill="#8989C2"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M29.6484 28.5946C29.6484 27.5497 30.4955 26.7027 31.5403 26.7027H33.4322C34.4771 26.7027 35.3241 27.5497 35.3241 28.5946C35.3241 29.6395 34.4771 30.4865 33.4322 30.4865H31.5403C30.4955 30.4865 29.6484 29.6395 29.6484 28.5946Z"
                    fill="#FF9900"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M42.8916 28.5946C42.8916 27.5497 43.7386 26.7027 44.7835 26.7027H46.6754C47.7202 26.7027 48.5673 27.5497 48.5673 28.5946C48.5673 29.6395 47.7202 30.4865 46.6754 30.4865H44.7835C43.7386 30.4865 42.8916 29.6395 42.8916 28.5946Z"
                    fill="#FF9900"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.4052 28.5946C16.4052 27.5497 17.2522 26.7027 18.297 26.7027H20.1889C21.2338 26.7027 22.0808 27.5497 22.0808 28.5946C22.0808 29.6395 21.2338 30.4865 20.1889 30.4865H18.297C17.2522 30.4865 16.4052 29.6395 16.4052 28.5946Z"
                    fill="#FF9900"
                />
                <path
                    d="M9.78357 11.0946C9.78357 10.3109 10.4188 9.67566 11.2025 9.67566H58.0268V12.5135H11.2025C10.4188 12.5135 9.78357 11.8782 9.78357 11.0946Z"
                    fill="#FF9900"
                />
                <path
                    d="M58.0268 9.67566V45.1486C58.0268 45.9323 57.3915 46.5676 56.6079 46.5676C55.8242 46.5676 55.189 45.9323 55.189 45.1486V9.67566L58.0268 9.67566Z"
                    fill="#FF9900"
                />
            </g>
            <defs>
                <clipPath id="clip0_45_57">
                    <rect width="82" height="82" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default StreamlinedCommunication;
