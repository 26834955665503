import React from "react";

function ReduceTime() {
    return (
        <svg
            width="82"
            height="82"
            viewBox="0 0 82 82"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_45_21)">
                <g style={{ mixBlendMode: "multiply" }}>
                    <circle
                        cx="78.2935"
                        cy="32.0962"
                        r="3"
                        transform="rotate(29.9033 78.2935 32.0962)"
                        fill="#E8E8FF"
                    />
                </g>
                <path
                    d="M5.33983 61.6414C5.06762 61.6414 4.80656 61.5334 4.61407 61.341C4.42158 61.1487 4.31343 60.8878 4.31343 60.6158V59.3469H3.02638C2.75416 59.3469 2.49311 59.2388 2.30062 59.0465C2.10813 58.8541 2 58.5933 2 58.3212C2 58.0492 2.10813 57.7883 2.30062 57.596C2.49311 57.4036 2.75416 57.2956 3.02638 57.2956H4.31343V56.0257C4.31343 55.7536 4.42158 55.4928 4.61407 55.3004C4.80656 55.1081 5.06762 55 5.33983 55C5.61205 55 5.87313 55.1081 6.06562 55.3004C6.2581 55.4928 6.36623 55.7536 6.36623 56.0257V57.2942H7.65467C7.92688 57.2942 8.18796 57.4023 8.38045 57.5946C8.57293 57.787 8.68107 58.0478 8.68107 58.3199C8.68107 58.5919 8.57293 58.8528 8.38045 59.0451C8.18796 59.2375 7.92688 59.3455 7.65467 59.3455H6.36761V60.6144C6.3678 60.7493 6.34134 60.883 6.28975 61.0077C6.23817 61.1323 6.16247 61.2456 6.067 61.341C5.97152 61.4364 5.85814 61.5121 5.73336 61.5636C5.60859 61.6152 5.47486 61.6416 5.33983 61.6414Z"
                    fill="#4D469D"
                />
                <path
                    d="M2.36722 54.1824C2.36715 53.9485 2.29767 53.7198 2.16754 53.5253C2.03741 53.3309 1.8525 53.1793 1.63617 53.0899C1.41985 53.0004 1.18184 52.9771 0.952247 53.0228C0.722652 53.0686 0.511784 53.1813 0.346315 53.3468C0.180846 53.5123 0.0681987 53.7231 0.0226347 53.9526C-0.0229294 54.182 0.000626402 54.4198 0.090327 54.6359C0.180028 54.852 0.331854 55.0367 0.526581 55.1665C0.721308 55.2964 0.950202 55.3656 1.18431 55.3655C1.49817 55.3653 1.79908 55.2406 2.02091 55.0187C2.24274 54.7968 2.36731 54.496 2.36722 54.1824Z"
                    fill="#4D469D"
                />
                <path
                    d="M72.9841 34.2168C73.1106 33.8977 73.1395 33.5482 73.0672 33.2126C72.995 32.877 72.8248 32.5702 72.5782 32.3312C72.3316 32.0922 72.0196 31.9316 71.6818 31.8697C71.344 31.8079 70.9955 31.8476 70.6804 31.9838C70.3652 32.12 70.0977 32.3466 69.9115 32.6349C69.7253 32.9232 69.6289 33.2603 69.6344 33.6035C69.6399 33.9468 69.7471 34.2807 69.9425 34.5631C70.1379 34.8455 70.4126 35.0637 70.732 35.1901C70.9439 35.2741 71.1702 35.3155 71.3981 35.312C71.626 35.3085 71.8509 35.2602 72.06 35.1698C72.2691 35.0795 72.4584 34.9488 72.617 34.7852C72.7755 34.6217 72.9003 34.4285 72.9841 34.2168Z"
                    fill="#4D469D"
                />
                <path
                    d="M74.9706 39.0439C75.0283 38.8977 75.0413 38.7376 75.008 38.5839C74.9747 38.4302 74.8966 38.2899 74.7835 38.1805C74.6705 38.0711 74.5275 37.9977 74.3728 37.9694C74.218 37.9412 74.0584 37.9594 73.9141 38.0218C73.7698 38.0843 73.6472 38.188 73.5619 38.3201C73.4766 38.4521 73.4324 38.6065 73.4349 38.7637C73.4374 38.921 73.4864 39.0739 73.5758 39.2033C73.6652 39.3328 73.791 39.4328 73.9372 39.4909C74.1333 39.5685 74.3522 39.5651 74.5458 39.4815C74.7393 39.3978 74.8916 39.2407 74.9693 39.0448L74.9706 39.0439Z"
                    fill="#4D469D"
                />
                <ellipse cx="29" cy="36.5" rx="23" ry="22.5" fill="#F3F3FF" />
                <g clipPath="url(#clip1_45_21)">
                    <path
                        d="M12.6451 75.9068C10.9765 75.9068 9.41113 75.2602 8.23697 74.0863C5.80626 71.6556 5.80626 67.7006 8.23697 65.2699L15.634 57.8729L16.9754 59.4627C18.7265 61.5383 20.7773 63.3229 23.071 64.7665L25.0975 66.042L17.0533 74.0863C15.8792 75.2602 14.3136 75.9068 12.6451 75.9068ZM15.4456 63.4668L10.9398 67.9727C9.99936 68.913 9.99936 70.4432 10.9398 71.3836C11.392 71.8358 11.9976 72.0848 12.6451 72.0848C13.2925 72.0848 13.8983 71.8358 14.3505 71.3836L19.0762 66.6579C17.7826 65.6936 16.5695 64.6272 15.4456 63.4668Z"
                        fill="#8989C2"
                    />
                    <path
                        d="M61.355 75.9068C59.6865 75.9068 58.1211 75.2602 56.9469 74.0863L48.9028 66.0421L50.9293 64.7667C53.223 63.3229 55.2738 61.5383 57.0249 59.4629L58.3663 57.873L65.7633 65.2701C68.194 67.7008 68.194 71.6558 65.7633 74.0865C64.589 75.2602 63.0236 75.9068 61.355 75.9068ZM54.9241 66.6579L59.6498 71.3834C60.102 71.8356 60.7076 72.0846 61.3552 72.0846C62.0029 72.0846 62.6085 71.8356 63.0605 71.3834C64.0009 70.4432 64.0009 68.913 63.0605 67.9725L58.5547 63.4666C57.4306 64.6272 56.2175 65.6936 54.9241 66.6579Z"
                        fill="#8989C2"
                    />
                    <path
                        d="M12.2122 33.7505L10.3581 32.9314C5.28075 30.6887 2 25.6555 2 20.109C2 12.381 8.28726 6.09369 16.0153 6.09369C21.97 6.09369 27.2884 9.87132 29.2491 15.4939L29.9167 17.4082L27.9664 17.9617C21.0596 19.9219 15.4353 25.1145 12.9209 31.8518L12.2122 33.7505ZM16.0153 9.91585C10.3948 9.91585 5.82216 14.4885 5.82216 20.109C5.82216 23.4828 7.49187 26.5958 10.1993 28.4787C13.133 22.2687 18.4053 17.4012 24.8229 14.9777C23.0219 11.8883 19.6848 9.91585 16.0153 9.91585Z"
                        fill="#8989C2"
                    />
                    <path
                        d="M61.7879 33.7504L61.0793 31.8514C58.5649 25.1142 52.9406 19.9217 46.0338 17.9613L44.0835 17.4078L44.751 15.4935C46.7118 9.87089 52.0301 6.09326 57.9849 6.09326C65.7129 6.09326 72.0002 12.3805 72.0002 20.1086C72.0002 25.6549 68.7194 30.6881 63.6421 32.9313L61.7879 33.7504ZM49.1773 14.9779C55.5949 17.4011 60.8672 22.2686 63.8009 28.4789C66.5083 26.5957 68.178 23.4828 68.178 20.1091C68.178 14.4886 63.6054 9.916 57.9849 9.916C54.3154 9.9158 50.9783 11.8882 49.1773 14.9779Z"
                        fill="#8989C2"
                    />
                    <path
                        d="M37.0003 72.5983C20.4526 72.5983 6.99023 59.1359 6.99023 42.5882C6.99023 26.0405 20.4526 12.5779 37.0003 12.5779C53.548 12.5779 67.0104 26.0404 67.0104 42.588C67.0104 59.1357 53.5478 72.5983 37.0003 72.5983ZM37.0003 16.4001C22.5602 16.4001 10.8124 28.1481 10.8124 42.588C10.8124 57.028 22.5604 68.7759 37.0003 68.7759C51.4402 68.7759 63.1882 57.028 63.1882 42.588C63.1882 28.1481 51.4402 16.4001 37.0003 16.4001Z"
                        fill="#8989C2"
                    />
                    <path
                        d="M36.9999 66.1138C24.0279 66.1138 13.4741 55.5603 13.4741 42.588C13.4741 29.6158 24.0277 19.0624 36.9999 19.0624V22.8846C26.1352 22.8846 17.2963 31.7235 17.2963 42.5882C17.2963 53.4529 26.1352 62.2918 36.9999 62.2918C47.8646 62.2918 56.7035 53.4529 56.7035 42.5882H60.5256C60.5255 55.5603 49.9719 66.1138 36.9999 66.1138Z"
                        fill="#FF9900"
                    />
                    <path
                        d="M37.3773 47.0748L27.4995 42.136L29.2088 38.7173L36.6226 42.424L46.4559 32.5909L49.1587 35.2936L37.3773 47.0748Z"
                        fill="#FF9900"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_45_21">
                    <rect width="82" height="82" fill="white" />
                </clipPath>
                <clipPath id="clip1_45_21">
                    <rect
                        width="70"
                        height="70"
                        fill="white"
                        transform="translate(2 6)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

export default ReduceTime;
