import React, { ReactElement } from "react";
import styles from "./styles.module.scss";

import MinimizedPatient from "./icons/minimizedPatient";
import ReduceTime from "./icons/reduceTime";
import EnhanceSecurity from "./icons/enhanceSecurity";
import SuperiorPatient from "./icons/superiorPatient";
import MachineLearning from "./icons/machineLearning";
import StreamlinedCommunication from "./icons/streamlinedCommunication";
import FeatureList from "@components/shared/featureList";

const benefitsList = [
    {
        description: "Reducing Patient No-Shows and Improving Follow-Up Care",
        icon: <MinimizedPatient />,
    },
    {
        description:
            "Reduced Time Spent on Administrative Tasks to Increase Quality Care",
        icon: <ReduceTime />,
    },
    {
        description: "Streamlined Communication Between Departments",
        icon: <StreamlinedCommunication />,
    },
    {
        description: "Enhanced Security to Protect Your Sensitive Patient Data",
        icon: <EnhanceSecurity />,
    },
    {
        description: "Superior Patient Engagement to Boost Satisfaction Levels",
        icon: <SuperiorPatient />,
    },
    {
        description:
            "Use of Machine Learning Technology to Reduce Readmission Rates",
        icon: <MachineLearning />,
    },
];

export default function Benefits(): ReactElement {
    return (
        <FeatureList
            featureList={benefitsList}
            mainTitle="The Benefits of Medical Software Solutions"
            classes={{
                mainTitleClasses:
                    "text-center font-bold text-3xl mb-14 mt-14 md:mt-24",
                innerSectionClasses: "lg:flex flex-wrap justify-between",
                oneBoxClasses: `text-center mb-12 lg:mb-6 w-full lg:w-[33%] py-8 ${styles["item"]}`,
                iconClasses: "children:mx-auto",
                descriptionClasses:
                    "font-light text-base lg:text-lg text-center w-4/5 mx-auto mt-2",
            }}
        />
    );
}
