import React from "react";

function EnhanceSecurity() {
    return (
        <svg
            width="82"
            height="82"
            viewBox="0 0 82 82"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <ellipse
                cx="47.482"
                cy="54.494"
                rx="26.988"
                ry="26.506"
                transform="rotate(-180 47.482 54.494)"
                fill="#F3F3FF"
            />
            <g style={{ mixBlendMode: "multiply" }}>
                <circle
                    cx="10.4575"
                    cy="62.2888"
                    r="2.89157"
                    transform="rotate(-167.275 10.4575 62.2888)"
                    fill="#E8E8FF"
                />
            </g>
            <path
                d="M63.5054 10.5466C47.9574 6.8138 31.5213 6.8138 15.9734 10.5466C15.233 10.7243 14.7111 11.3865 14.7111 12.1477V49.7501C14.7111 54.7416 17.0755 59.2482 21.2029 62.1155C27.2336 66.2885 33.3541 70.858 38.7543 74.8896C39.0464 75.1078 39.3929 75.2168 39.7393 75.2168C40.0863 75.2168 40.4334 75.1073 40.7257 74.8887C46.1281 70.8469 52.2513 66.266 58.2903 62.0792C62.4072 59.2135 64.7674 54.7103 64.7674 49.7246V12.1479C64.7676 11.3866 64.2456 10.7245 63.5054 10.5466ZM61.4743 49.7249C61.4743 53.6176 59.6287 57.1354 56.4116 59.3746C50.6917 63.3403 44.9163 67.6421 39.7384 71.5148C34.563 67.652 28.791 63.3615 23.0795 59.4094C19.8537 57.1682 18.0044 53.6476 18.0044 49.7505V13.4548C32.3298 10.2346 47.1491 10.2346 61.4745 13.4548V49.7249H61.4743Z"
                fill="#8989C2"
            />
            <path
                d="M22.6488 15.8671C21.8664 16.0098 21.2976 16.6915 21.2976 17.487V49.7515C21.2976 52.554 22.6278 55.0858 24.9507 56.6998C29.8547 60.0931 34.6798 63.6459 38.7529 66.6739C39.0445 66.8905 39.3898 66.9991 39.7353 66.9991C40.0812 66.9991 40.4273 66.8904 40.719 66.6731C44.7943 63.6371 49.6218 60.0754 54.5278 56.6739C56.8499 55.0574 58.1813 52.5246 58.1813 49.7251V17.4867C58.1813 16.6913 57.6125 16.0095 56.83 15.8668C51.2017 14.8405 45.4514 14.3201 39.7394 14.3201C34.0274 14.3201 28.2773 14.8406 22.6488 15.8671ZM54.8882 49.725C54.8882 51.4314 54.0714 52.9788 52.649 53.9689C48.0946 57.1265 43.6153 60.4154 39.7344 63.3004C35.8558 60.4232 31.3791 57.1429 26.8272 53.9933C25.4057 53.0055 24.5908 51.4595 24.5908 49.7515V18.8709C29.5306 18.0362 34.6813 17.6136 39.7395 17.6136C44.7978 17.6136 49.9484 18.0364 54.8882 18.8709V49.725Z"
                fill="#8989C2"
            />
            <path
                d="M32.7904 37.339C32.1528 36.6902 31.1101 36.6815 30.4617 37.3191C29.8133 37.9566 29.8043 38.9991 30.4418 39.6477L36.8088 46.1243C37.1187 46.4396 37.5418 46.6166 37.9829 46.6166C37.994 46.6166 38.0052 46.6166 38.0161 46.6162C38.469 46.607 38.8984 46.4117 39.2026 46.0759L50.9483 33.1226C51.5591 32.449 51.5081 31.4075 50.8346 30.7968C50.1609 30.1858 49.1195 30.2367 48.5087 30.9104L37.9347 42.5717L32.7904 37.339Z"
                fill="#FF9900"
            />
            <path
                d="M12.1519 68.173C12.1263 68.5028 12.1992 68.8328 12.3612 69.1213C12.5233 69.4098 12.7674 69.6438 13.0625 69.7937C13.3577 69.9437 13.6906 70.0027 14.0193 69.9635C14.348 69.9243 14.6576 69.7885 14.9091 69.5734C15.1605 69.3583 15.3424 69.0734 15.4317 68.7549C15.5211 68.4364 15.5139 68.0986 15.4111 67.7841C15.3083 67.4696 15.1145 67.1926 14.8542 66.9882C14.5939 66.7837 14.2788 66.661 13.9487 66.6355C13.7297 66.6185 13.5095 66.6448 13.3006 66.7129C13.0918 66.781 12.8984 66.8895 12.7316 67.0322C12.5647 67.175 12.4277 67.3492 12.3282 67.545C12.2287 67.7407 12.1689 67.9541 12.1519 68.173Z"
                fill="#4D469D"
            />
            <path
                d="M16.0673 64.5087C16.0558 64.6597 16.0894 64.8109 16.1638 64.9429C16.2382 65.075 16.3501 65.182 16.4853 65.2505C16.6206 65.3191 16.7731 65.346 16.9237 65.328C17.0742 65.3099 17.216 65.2477 17.3311 65.1491C17.4463 65.0505 17.5296 64.9201 17.5705 64.7742C17.6114 64.6283 17.6082 64.4736 17.5612 64.3295C17.5141 64.1854 17.4254 64.0585 17.3063 63.9648C17.1871 63.8711 17.0428 63.8147 16.8917 63.8029C16.6889 63.7873 16.4883 63.8527 16.3339 63.9848C16.1795 64.1169 16.0839 64.305 16.0682 64.5075L16.0673 64.5087Z"
                fill="#4D469D"
            />
            <path
                d="M68.3412 4.22046C68.3412 4.48284 68.2371 4.73446 68.0517 4.91999C67.8663 5.10552 67.6148 5.20976 67.3526 5.20976L66.1296 5.20976L66.1296 6.45029C66.1296 6.71267 66.0254 6.96429 65.8401 7.14982C65.6547 7.33535 65.4032 7.43958 65.141 7.43958C64.8788 7.43958 64.6274 7.33535 64.442 7.14982C64.2566 6.96429 64.1524 6.71267 64.1524 6.45029L64.1524 5.20976L62.9284 5.20976C62.6662 5.20976 62.4148 5.10552 62.2294 4.91999C62.044 4.73446 61.9398 4.48284 61.9398 4.22046C61.9398 3.95808 62.044 3.70644 62.2294 3.52091C62.4148 3.33538 62.6662 3.23116 62.9284 3.23116L64.1511 3.23116L64.1511 1.98929C64.1511 1.72691 64.2553 1.47528 64.4407 1.28975C64.6261 1.10422 64.8775 0.999993 65.1397 0.999993C65.4019 0.999993 65.6533 1.10422 65.8387 1.28975C66.0241 1.47528 66.1283 1.72691 66.1283 1.98929L66.1283 3.22983L67.3513 3.22983C67.4813 3.22965 67.6101 3.25515 67.7303 3.30487C67.8505 3.35459 67.9597 3.42756 68.0517 3.51958C68.1436 3.6116 68.2165 3.72088 68.2662 3.84115C68.3159 3.96142 68.3414 4.09032 68.3412 4.22046Z"
                fill="#4D469D"
            />
            <path
                d="M70.3634 8.03342C70.1451 7.97682 69.915 7.98625 69.702 8.06054C69.4891 8.13484 69.3029 8.27064 69.1671 8.45079C69.0312 8.63094 68.9518 8.84733 68.9388 9.0726C68.9259 9.29787 68.98 9.5219 69.0943 9.71636C69.2086 9.91082 69.378 10.067 69.581 10.1651C69.7841 10.2632 70.0116 10.2988 70.235 10.2674C70.4583 10.2361 70.6673 10.1392 70.8356 9.98897C71.004 9.83876 71.124 9.642 71.1806 9.42356C71.2564 9.13071 71.213 8.81977 71.0597 8.55907C70.9065 8.29838 70.656 8.1093 70.3634 8.03342Z"
                fill="#4D469D"
            />
        </svg>
    );
}

export default EnhanceSecurity;
